import { createRouter, createWebHistory } from 'vue-router';
import content from './views/content.vue';


const routes = [
  {
    path: "",
    name: "content",
    component: content,
    children: [
      {
        path: '',
        meta: { title: 'Plugzmart | Top EV Charger Manufacturer In India' },
        component: () => import(/* webpackChunkName: "dashboard" */ './views/Home.vue'),
      },
      {
        path: 'about',
        meta: { title: 'Plugzmart | About Us' },
        component: () => import(/* webpackChunkName: "dashboard" */ './views/about.vue'),
      },

      {
        path: 'contact-us',
        meta: { title: 'Plugzmart | Contact Us' },

        component: () => import(/* webpackChunkName: "dashboard" */ './views/contact-us.vue'),
      },
      {
        path: 'ac-chargers',
        meta: { title: 'Plugzmart | AC Chargers' },

        component: () => import(/* webpackChunkName: "dashboard" */ './views/ac-chargers.vue'),
      },
      {
        path: 'dc-chargers',
        meta: { title: 'Plugzmart | DC Chargers' },

        component: () => import(/* webpackChunkName: "dashboard" */ './views/dc-chargers.vue'),
      },
      {
        path: 'privacy-policy',
        meta: { title: 'Plugzmart | Privacy Policy' },

        component: () => import(/* webpackChunkName: "dashboard" */ './views/privacy-policy.vue'),
      },

      {
        path: 'terms-and-conditions',
        meta: { title: 'Plugzmart | Terms and Conditions' },

        component: () => import(/* webpackChunkName: "dashboard" */ './views/terms-and-conditions.vue'),
      },
      {
        path: 'faq',
        meta: { title: 'Plugzmart | FAQ' },

        component: () => import(/* webpackChunkName: "dashboard" */ './views/faq.vue'),
      },


      {
        path: 'charge-details',
        meta: { title: 'Plugzmart | Charge Details' },

        component: () => import(/* webpackChunkName: "dashboard" */ './views/charge-details.vue'),
      },
      {
        path: 'portable-chargers',
        meta: { title: 'Plugzmart | Portable EV Chargers' },

        component: () => import(/* webpackChunkName: "dashboard" */ './views/portable-chargers.vue'),
      },
      {
        path: 'ev-charging-management-system',
        meta: { title: 'Plugzmart | Charging Management System' },

        component: () => import(/* webpackChunkName: "dashboard" */ './views/ev-charging-management-system.vue'),

      },
      {
        path: 'ocpp-api-service',
        meta: { title: 'Plugzmart | OCPP - API as a Service' },

        component: () => import(/* webpackChunkName: "dashboard" */ './views/ocpp-api-service.vue'),
      },
      {
        path: 'ocpi-api-service',
        meta: { title: 'Plugzmart | OCPI - API as a Service' },

        component: () => import(/* webpackChunkName: "dashboard" */ './views/ocpi-api-service.vue'),
      },
      {
        path: 'ev-charging-management-system-whitelabelling',
        meta: { title: 'Plugzmart | Charging Management System White Labelling' },
        component: () => import(/* webpackChunkName: "dashboard" */ './views/ev-charging-management-system-whitelabelling.vue'),

      },

    ]
  }

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach((to, from, next) => {

  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  } else {

    document.title = 'Best EV Charger Manufacturer in India | EV Charging Stations India';
  }

  if (window.location.hostname.startsWith('www.')) {

    const nonWWW = window.location.href.replace('www.', '');
    window.location.replace(nonWWW);
  } else {
    next();
  }

  // Track Google Analytics page view
  if (window.gtag) {
    window.gtag('config', 'G-YSY46VEB5N', {
      page_path: to.fullPath,
    });
  }

  next();
});



export default router;