import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VueGtag from 'vue-gtag';
import { createVuetify } from 'vuetify';
import 'vuetify/styles'; // Import Vuetify styles


import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
const vuetify = createVuetify();

const app = createApp(App);

app.config.productionTip = false;

app.use(VueGtag, {
  config: { id: 'G-YSY46VEB5N' } // Replace with your actual Google Analytics ID
});
app.use(vuetify); // Add Vuetify here
app.use(router);
app.mount('#app');

createApp(App).use(router).mount('#app');

